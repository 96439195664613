var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.phone
    ? _c(
        "div",
        [
          _vm.isSuceess
            ? _c(
                "div",
                {
                  staticClass: "phone-success",
                  class: _vm.isPointer ? "hover-dom" : "",
                  on: {
                    click: function ($event) {
                      return _vm.handlePhoneClick("success")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "phone-icon-box" }),
                  _c("span", [_vm._v(_vm._s(_vm.phone))]),
                ]
              )
            : [
                _vm.tips
                  ? _c(
                      "el-tooltip",
                      {
                        staticClass: "item",
                        attrs: {
                          effect: "dark",
                          content: _vm.tips,
                          placement: "bottom",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "phone-error hover-dom",
                            on: {
                              click: function ($event) {
                                return _vm.handlePhoneClick("error")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "phone-icon-box" }),
                            _c("span", [_vm._v(_vm._s(_vm.phone))]),
                          ]
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "phone-error",
                        on: {
                          click: function ($event) {
                            return _vm.handlePhoneClick("error")
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "phone-icon-box" }),
                        _c("span", [_vm._v(_vm._s(_vm.phone))]),
                      ]
                    ),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }